/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title, image, pageURI }) => {
  const { wp, seoImage } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        },
        seoImage: file(relativePath: {eq: "blogs/tech-blog-seo-image.jpg"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const metaDescription = description || wp.generalSettings?.description
  const defaultTitle = wp.generalSettings?.title
  const host = process.env.GATSBY_SITE_URL

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[{ rel: `canonical`, href: `${host}${pageURI}` }]}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:image`,
          content: `${host}${image || seoImage?.childImageSharp?.fluid?.src}` || null,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: "@PhonePe_",
        },
        {
          name: `twitter:title`,
          content: title ? `${title} | ${defaultTitle}` : defaultTitle || null,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${host}${image}` || null,
        },
        {
          name: `viewport`,
          content: "width=device-width, minimum-scale=1.0 initial-scale=1",
        },
        {
          name: `google-site-verification`,
          content: "3KzMhJrM90r1oyrHCnE06j3wIDLGi6uqzPdihmIR0YU",
        },
        {
          name: `msvalidate.01`,
          content: "BDB09266990A91A6019EC4DB63C312BE",
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  pageURI: process.env.GATSBY_SITE_URL,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  pageURI: PropTypes.string,
}

export default Seo
