import React, { useRef, useState } from 'react'
import classNames from 'classnames';
import { Link } from 'gatsby';

import pp_logo from "../../images/header/ppl.svg";
import HamburgerMenuIcon from "../../images/header/hamburger-menu.svg";

import './index.scss';

const Header = () => {
    const headerRef = useRef(null);
    const [showMobPanel, setShowMobPanel] = useState(false);

    const handleMobileNavbarPanel = e => {
      setShowMobPanel(!showMobPanel);
    };

    return (
    <>
      <div className="header-container" ref={headerRef}>
        <header>
          <div className="logo">
            <Link to="https://www.phonepe.com/">
              <img src={pp_logo} alt="PhonePe Logo" />
            </Link>
          </div>
          <div className="navigation-links">
            <div className="logo mobile-logo">
              <Link to="https://www.phonepe.com/">
                <img src={pp_logo} alt="phonepe logo" />
              </Link>
            </div>
            <Link
              to="https://www.phonepe.com/business-solutions/"
              activeClassName="active"
            >
              Business Solutions
            </Link>
            <Link to="https://www.phonepe.com/press/" activeClassName="active">
              Press
            </Link>

            <Link to="https://www.phonepe.com/careers/" activeClassName="active">
              Careers
            </Link>

            <Link to="https://www.phonepe.com/about-us/" activeClassName="active">
              About Us
            </Link>
            <Link to="https://www.phonepe.com/blog/" activeClassName="active">
              Blog
            </Link>
            <Link
              to="https://www.phonepe.com/contact-us/"
              activeClassName="active"
            >
              Contact Us
            </Link>
            <Link
              to="https://www.phonepe.com/trust-and-safety/"
              activeClassName="active"
            >
              Trust &amp; Safety
            </Link>
          </div>
          <span
            className="pp-hamburger-icon"
            onClick={handleMobileNavbarPanel}
          >
            <img
              src={HamburgerMenuIcon}
              alt="hamburger menu"
            />
          </span>
        </header>
      </div>
      <div
        className={classNames("mobile-navigation", {
          "mobile-navigation-hide": !showMobPanel,
        })}
      >
        <div
          className={classNames("mobile-overlay", {
            "mobile-navigation__overlay": showMobPanel,
          })}
          onClick={handleMobileNavbarPanel}
        ></div>
        <div
          className={classNames(
            "mobile-navigation__sidepanel",
            {
              "show-mobile-nav": showMobPanel,
            }
          )}
        >
          <div
            className="mobile-navigation__sidepanel__close"
            onClick={handleMobileNavbarPanel}
          >
            &#10005;
          </div>
          <Link to="https://www.phonepe.com/" activeClassName="active">
            Home
          </Link>
          <Link
            to="https://www.phonepe.com/business-solutions/"
            activeClassName="active"
          >
            Business Solutions
          </Link>
          <Link to="https://www.phonepe.com/press/" activeClassName="active">
            Press
          </Link>

          <Link to="https://www.phonepe.com/careers/" activeClassName="active">
            Careers
          </Link>
          <Link to="https://www.phonepe.com/about-us/" activeClassName="active">
            About Us
          </Link>
          <Link to="https://www.phonepe.com/blog/" activeClassName="active">
            Blog
          </Link>
          <Link to="https://www.phonepe.com/contact-us/" activeClassName="active">
            Contact Us
          </Link>
          <Link
            to="https://www.phonepe.com/trust-and-safety/"
            activeClassName="active"
          >
            Trust &amp; Safety
          </Link>
        </div>
      </div>
    </>
  );
}

export default Header