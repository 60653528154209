// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";

// custom CSS styles
import "./src/css/main.scss";
import getUrlQueryParams from "./src/helpers/queryString";
import { sendCustomEvent } from "./src/helpers/garfield";

const garfieldNamespace =
  process.env.GATSBY_GARFIELD_NAMESPACE || "";
const garfieldSDKUrl =
  process.env.GATSBY_GARFIELD_SDK_URL || "";

const addGarfield = () => {
  let objectName = "gfd";
  window["GaNamespaceId"] = garfieldNamespace;
  window["GarfieldObjName"] = objectName;
  window[objectName] =
    window[objectName] ||
    function () {
      window[objectName].q = window[objectName].q || [];
      window[objectName].q.push(arguments);
    };
  window[objectName].l = 1 * new Date();
  let a = document.createElement("script"),
    m = document.getElementsByTagName("script")[0];
  a.async = 1;
  a.src =
    process.env.GATSBY_GARFIELD_JS_FILE || garfieldSDKUrl;
  m.parentNode.insertBefore(a, m);
};

export const onClientEntry = () => {
  addGarfield();
  window.gfd &&
    window.gfd("create", { id: garfieldNamespace });
  window.gfd && window.gfd("send", "pageview");

  const queryParams = getUrlQueryParams(window.location);
  const utm_source = queryParams?.["utm_source"];
  const utm_entry = window?.location?.href;
  const referrer = document?.referrer;

  if (utm_source) {
    sessionStorage.setItem("utm_source", utm_source);
    sessionStorage.setItem("utm_entry", utm_entry);

    sendCustomEvent("utm_source", utm_source);
  }
  utm_entry && sendCustomEvent("pageview", utm_entry);
  utm_entry && sendCustomEvent("entry_url", utm_entry);

  referrer && sendCustomEvent("referrer", referrer);
};

export const onRouteUpdate = ({
  location,
  prevLocation,
}) => {
  if (
    !window.gfd ||
    !prevLocation ||
    prevLocation.href === location.href
  )
    return;

  window.gfd("send", "pageview");

  const queryParams = getUrlQueryParams(window.location);
  const utm_source = queryParams?.["utm_source"];
  const pageurl = window?.location?.href;

  if (utm_source) {
    sessionStorage.setItem("utm_source", utm_source);
    sendCustomEvent("utm_source", utm_source);
  }
  pageurl && sendCustomEvent("pageview", pageurl);
};
