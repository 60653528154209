import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout.js";
import Seo from "../../components/seo.js";
import WidgetContainer from "../../components/WidgetContainer/WidgetContainer.js";
import BlogGrid from "../../components/BlogGrid/index.js";
import BlogCard from "../../components/BlogCard/index.js";
import BlogsSubNavBar from "../../components/CategoryPage/BlogsSubNavBar/index.js";
import { flattenBlogData } from "../../utils/index.js";

const CategoryPage = ({ data, pageContext, location }) => {
  const posts = data.allWpPost.nodes;
  const seoTitle = data.wpCategory?.name || "Home";

  const flattenBlogs = flattenBlogData(posts);

  return (
    <Layout isHomePage>
      <Seo title={`${seoTitle} | All posts`} />
      <WidgetContainer>
        <BlogsSubNavBar
          categoryList={data.categoriesData.nodes}
          location={location}
        />
        <BlogGrid>
          {flattenBlogs.map(blogPost => (
            <BlogCard
              key={blogPost.uri}
              postData={blogPost}
              defaultImage={data.defaultImage}
            />
          ))}
        </BlogGrid>
      </WidgetContainer>
    </Layout>
  );
};

export default CategoryPage;

export const pageQuery = graphql`
  query WordPressIndividualCategoryPosts($id: String!) {
    defaultImage: file(
      relativePath: { eq: "blogs-default-image.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { id: { eq: $id } } }
        }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            slug
            id
            name
          }
        }
        author {
          node {
            id
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        subtitleAcf {
          text
        }
      }
    }
    categoriesData: allWpCategory(
      filter: { count: { gt: 0 } }
    ) {
      nodes {
        id
        slug
        name
        count
        uri
      }
    }
    recentPosts: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 5
    ) {
      nodes {
        id
        title
        uri
      }
    }
    wpCategory(id: { eq: $id }) {
      name
    }
  }
`;
