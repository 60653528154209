import React from "react"
import NewHeader from "../NewHeader"
import "./Layout.css"

const Layout = ({ isHomePage, children }) => {
  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <NewHeader />
      <main>{children}</main>
    </div>
  )
}

export default Layout
