/**
 * Gets URL query params
 * @param {Location} location window.location
 * @returns {Object.<string, string>} Object containing queryparam key value pairs
 */
function getUrlQueryParams(location) {
  let result = {};
  const queryString = location.search.split("?")[1] ?? "";
  const queryKeyVals = queryString.split("&");
  queryKeyVals.forEach(queryKV => {
    const [key, value] = queryKV.split("=");
    if (key) {
      result[key] = decodeURIComponent(value);
    }
  });
  return result;
}

export default getUrlQueryParams;
