const logger =
  process.env.GATSBY_ACTIVE_ENV === "production"
    ? { log: () => {}, error: () => {}, warn: () => {} }
    : console;

const EVENT_TYPES = {
  CLICK: "click",
  CUSTOM: "custom",
  PAGE_VIEW: "pageview",
};

export const garfieldTags = {
  entry_url: "entry_url",
  utm_source: "utm_source",
};

const garfieldTagsSchema = {
  0: garfieldTags.entry_url,
  1: garfieldTags.utm_source,
};

const getFormattedTags = tags => {
  let result = {};
  Object.keys(garfieldTagsSchema)
    .slice(0, 20)
    .forEach(tagNum => {
      const tagValue = tags[garfieldTagsSchema[tagNum]];
      result[`t${tagNum}`] = tagValue ? tagValue : "";
    });
  return result;
};

const sendEvent = (
  eventType,
  eventName,
  value,
  tags = {}
) => {
  if (!Object.values(EVENT_TYPES).includes(eventType)) {
    logger.warn(
      `eventType must be one of ${Object.values(
        EVENT_TYPES
      ).join(", ")}`
    );
    return;
  }
  if (!eventName || typeof eventName !== "string") {
    logger.warn("eventName must be a non-empty string");
    return;
  }
  if (!value || typeof value !== "string") {
    logger.warn("value must be a non-empty string");
    return;
  }
  if (typeof tags !== "object") {
    logger.warn("tags must be an object");
    return;
  }

  if (typeof window !== "undefined" && window.gfd) {
    const payload = {
      eventType,
      eventName,
      value,
      data: {
        tags: getFormattedTags(tags),
      },
    };

    logger.log(
      `%c${eventName} %c${JSON.stringify(payload)}`,
      "color: rgb(243, 198, 105)",
      "color: rgb(111, 197, 110)"
    );

    window.gfd("send", payload);
  }
};

const modifyTagsObject = (tags = {}) => {
  let result = {};

  let val_utm_source = "";
  let val_entry_url = "";

  try {
    if (typeof window !== "undefined") {
      val_utm_source = window.sessionStorage.getItem(
        garfieldTags.utm_source
      );
      val_entry_url = window.sessionStorage.getItem(
        "utm_entry"
      );
    }
  } catch (e) {
    val_utm_source = "";
    val_entry_url = "";
  } finally {
    result = {
      [garfieldTags.utm_source]: val_utm_source,
      [garfieldTags.entry_url]: val_entry_url,
      ...tags,
    };
  }

  return result;
};

/**
 * Send click event to garfield
 * @param {String} eventName
 * @param {String} value
 */
export const sendClickEvent = (
  eventName,
  value,
  tags = {}
) => {
  const modifiedTags = modifyTagsObject(tags);
  sendEvent(
    EVENT_TYPES.CLICK,
    eventName,
    value,
    modifiedTags
  );
};

/**
 * Send custom event to garfield
 * @param {String} eventName
 * @param {String} value
 */
export const sendCustomEvent = (
  eventName,
  value,
  tags = {}
) => {
  const modifiedTags = modifyTagsObject(tags);
  sendEvent(
    EVENT_TYPES.CUSTOM,
    eventName,
    value,
    modifiedTags
  );
};
