module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wordpress-cms.phonepe.com/tech-blog/graphql","production":{"allow404Images":true,"hardCacheMediaFiles":false},"html":{"useGatsbyImage":true,"createStaticFiles":true,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"generateWebpImages":false},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PhonePe Tech Blog","short_name":"PhonePe Tech Blog","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/phonepe-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c7ac89f3ea36bf8b3608757e3c2b8bfe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
